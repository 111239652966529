exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contentful-page-url-js": () => import("./../../../src/pages/{contentfulPage.url}.js" /* webpackChunkName: "component---src-pages-contentful-page-url-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-contentful-post-url-js": () => import("./../../../src/pages/news/{contentfulPost.url}.js" /* webpackChunkName: "component---src-pages-news-contentful-post-url-js" */),
  "component---src-pages-products-contentful-product-sku-js": () => import("./../../../src/pages/products/{contentfulProduct.sku}.js" /* webpackChunkName: "component---src-pages-products-contentful-product-sku-js" */)
}

